/* eslint-disable no-undef, class-methods-use-this, no-invalid-this */

export class ChitChatsPrinterAppConnection {
  constructor(printer, $container) {
    this.printer = printer;
    this.container = $container;
    this.printersContainer = this.container.find(".js-configure__printers");
    this.loadingContainer = this.container.find(".js-configure__loading");
    this.loadingMessage = this.loadingContainer.find(".js-configure__loading-message");
    this.instructions = this.container.find(".js-configure__instructions");
    this.findPrintersBtn = this.container.find(".js-configure__find-printers");
    this.isActive = false;
    this.bindEvents();
  }

  bindEvents() {
    this.container.on("click", ".js-configure__find-printers, .js-configure__tab-link", ()=> {
      return this.showPrinters();
    });

    this.container.on("click", ".js-test__tab-link", () =>{
      this.startConnection();
    });
  }

  // eslint-disable-next-line no-empty-function
  startConnection() {
  }

  showPrinters(printers) {
    if (!printers) {
      return;
    }
    this.setActiveState(true);
    this.updateConnectionState("Active");
    // eslint-disable-next-line max-len
    let html = "<p>The following printers were detected in your system and can be managed by you as well as other account administrators.</p>";
    html += "<div class='row my-2 pt-4'>";
    // eslint-disable-next-line max-len
    html += "<div class='col-md-8'><strong class='d-block mb-3' style='color: #43a047; display: inline-block;'>Your printers on this computer:</strong></div>";
    html += "<div class='col-md-4'><strong>Selected</strong></div>";
    html += "</div>";
    for (let i = 0; i < printers.length; i += 1) {
      html += "<div class='my-2'>";
      // eslint-disable-next-line max-len
      html += "<i class='fa fa-print mr-2'></i> <label style='display: inline-block; width: 69%;' for='" + printers[i].deviceId + "'>" + printers[i].name + "</label>";
      // eslint-disable-next-line max-len
      html += "<input type='radio' class='js-set-printer mr-2'  data-id='" + printers[i].deviceId + "' data-name='" + printers[i].name + "' name='printer_radio' id='" + printers[i].deviceId + "' name='" + printers[i].name + "'>";
      // eslint-disable-next-line max-len
      html += "<a class='btn-link js-print-test-label pl-5 disabled' data-id='" + printers[i].deviceId + "' style='text-decoration: none;' href='#'>Print test label</a>";
      html += "<hr/>";
      html += "</div>";
    }
    this.printersContainer.html(html);
    $(".js-printer-settings").trigger("update");
  }

  displayMessage(msg, alertClass) {
    if (alertClass == undefined) {
      alertClass = "alert-info"; // eslint-disable-line no-param-reassign
    }

    var timeout = setTimeout(function() {
      $("#" + timeout).alert("close");
    }, 5000);

    var alertElement = $("<div/>").addClass("alert " + alertClass)
      .attr("id", timeout).attr("role", "alert");
    // eslint-disable-next-line max-len
    alertElement.html("<button type='button' class='close' data-dismiss='alert' aria-label='Close'><span aria-hidden='true'>&times;</span></button>" + msg);

    $(".js-notice").append(alertElement);
  }

  displayError(err) {
    this.displayMessage(err, "alert-danger");
  }

  updateConnectionState(text) {
    $(".js-connection-status").html(text);
  }

  handleConnectionError(err) {
    this.setActiveState(false);
    this.showLoading(false, "");
    this.updateConnectionState("Error");

    if (err.target != undefined) {
      if (err.target.readyState >= 2) { // If CLOSING or CLOSED
        this.displayError("Connection to Print App was closed.");
      } else {
        this.displayError("A connection error occurred.");
      }
    } else {
      this.displayError(err);
    }
  }

  showLoading(isVisible, text) {
    if (isVisible) {
      this.loadingContainer.removeClass("d-none");
      this.instructions.addClass("d-none");
      this.printersContainer.addClass("d-none");
      this.findPrintersBtn.addClass("disabled");
    } else {
      this.loadingContainer.addClass("d-none");
      this.instructions.removeClass("d-none");
      this.printersContainer.removeClass("d-none");
      this.findPrintersBtn.removeClass("disabled");
    }
    this.loadingMessage.html(text);
  }

  setActiveState(status) {
    this.isActive = status;
    $("#printer-nav").trigger("update");
  }
}
