export class ChitChatsPrinterAppStatus {
  constructor(printer) {
    this.printer = printer;
    this.statusContainer = $(".js-printer-setup").find(".js-printer-status");
    this.setStatus();
    this.bindEvents();
  }

  bindEvents() {
    this.statusContainer.on("update", (_e) => {
      this.setStatus();
    });
  }

  setStatus() {
    let printer = this.printer;
    let format = $(".js-printer-format").data(printer.format + "-value");
    let name = this.printer.name || "Unknown";

    this.setField("name", name);
    this.setField("format", format);
  }

  setField(field, info) {
    this.statusContainer.find(".js-printer-" + field).html(info);
  }
}
