/* eslint-disable no-invalid-this */

export class ChitChatsPrinterAppSettings {
  constructor(printer, status, $container, client_id) {
    this.printer = printer;
    this.status = status;
    this.container = $container;
    this.client_id = client_id;
    this.settingsContainer = this.container.find(".js-printer-settings");
    this.setActiveFormatDropdown(this.printer.format);
    this.bindEvents($container);
  }

  bindEvents() {
    this.container.on("click", ".js-set-printer", (e)=> {
      let name = $(e.target).data("name");
      let deviceId = $(e.target).data("id");
      let format = this.printerFormat(name);
      this.updatePrinter(name, format, deviceId);

      this.container.find(".js-print-test-label").addClass("disabled");
      this.container.find(".js-print-test-label[data-id='" + deviceId + "']").removeClass("disabled");
    });

    this.container.on("click", ".js-print-test-label", (e)=> {
      e.preventDefault();
      $.get("/clients/" + this.client_id + "/labels/chit_chats_printer_app_test");
    });

    this.container.on("click", ".js-format-btn", (e) => {
      e.preventDefault();
      var name = this.printer.name;
      var format = $(e.target).data("format-value");

      if (format == "reset") {
        format = this.printerFormat(name);
      }

      this.updatePrinter(name, format);
    });

    this.settingsContainer.on("update", (_e) => {
      this.setActivePrinterButton(this.printer.name);
    });
  }

  updatePrinter(name, format, id) {
    let info = {
      name: name,
      format: format,
      deviceId: id
    };

    this.printer.setPrinterInfo(info);
    this.printer.setCookie(info);
    this.setLabelUrl(format);
    this.setActiveFormatDropdown(format);
    this.setActivePrinterButton(name);
  }

  setActiveFormatDropdown(format) {
    $(".js-format-btn").removeClass("active");

    this.settingsContainer
      .find("a[data-format-value='" + format + "']")
      .addClass("active");
  }

  setActivePrinterButton(name) {
    this.container
      .find(".js-configure__printers")
      .find("input[data-name='" + name + "']")
      .prop("checked", true);

    $("#printer-nav").trigger("update");
  }

  setLabelUrl(format) {
    var url = this.container.find(".js-print-label").data(format + "-print-url");
    this.container.find(".js-print-label").data("url", url);
  }

  // eslint-disable-next-line class-methods-use-this
  printerFormat(name) {
    return name.match(/^(Zebra|raw|ZP)/) ? "zpl" : "pdf";
  }
}
