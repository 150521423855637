import Pusher from "pusher-js";
import { ChitChatsPrinterAppNav } from "./chit_chats_printer_app_nav";
import { ChitChatsPrinterAppConnection } from "./chit_chats_printer_app_connection";
import { ChitChatsPrinterAppSettings } from "./chit_chats_printer_app_settings";
import { ChitChatsPrinterAppStatus } from "./chit_chats_printer_app_status";
import Cookies from "js-cookie";

export class ChitChatsPrinterApp {
  // eslint-disable-next-line no-unused-vars
  constructor(app_key, cluster, channel, token, client_id, container) {
    this.pusher = new Pusher(app_key, {
      cluster: cluster
    });
    this.container = $(".js-printer-setup");
    this.name = "";
    this.deviceId = "";
    this.format = "pdf";
    this.printerNav = new ChitChatsPrinterAppNav(this, this.container);
    this.printerConnection = new ChitChatsPrinterAppConnection(this, this.container);
    this.printerStatus = new ChitChatsPrinterAppStatus(this);
    this.printerSettings = new ChitChatsPrinterAppSettings(this, "Unknown", this.container, client_id);

    const cache_channel = this.pusher.subscribe(channel);
    cache_channel.bind(token, (data) => {
      this.printers = data.message;
      this.printerConnection.showPrinters(this.printers);
    });
  }

  setPrinterInfo(info) {
    this.deviceId = info.deviceId;
    this.name = info.name.replace(new RegExp("\\+", "g"), " ");
    this.format = info.format;
    this.type = (info.format == "zpl") ? "raw" : "image";
    this.isLabelPrinter = this._isLabelPrinter(this.name);
    this.isMac = this._isMac();

    $(".js-printer-status").trigger("update");
  }

  setCookie(info) {
    // Stringify and encode cookie into Base64
    var encodedValue = btoa(JSON.stringify(info));
    // Expire cookie in 20 years (similar to Rails' cookie.permanent)
    Cookies.set("printer", encodedValue, { expires: (365 * 20), path: "/" });
    this.cookie = Cookies.get("printer");
  }

  // eslint-disable-next-line class-methods-use-this
  _isLabelPrinter(name) {
    return Boolean(name.match(/^(Dymo|LabelWriter|Zebra)/i));
  }

  // eslint-disable-next-line class-methods-use-this
  _isMac() {
    var platform = window.navigator.platform,
      macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"];

    return (macosPlatforms.indexOf(platform) !== -1);
  }
}
