/* eslint-disable class-methods-use-this */

class ChitChatsPrinterAppNav {
  constructor(printer, $container) {
    this.printer = printer;
    this.navigation = $container.find("#printer-nav");
    this.enableTestTab(printer, $container);
    this.bindEvents($container);
  }

  bindEvents($container) {
    var self = this;

    $container.on("click", ".js-next-btn", function(e) {
      $container.find(".nav-link, .tab-pane").removeClass("active");
      self.setActiveTab($(e.target).data("tab"));
    });

    this.navigation.on("update", function(_e) {
      self.enableTestTab();
    });
  }


  setActiveTab(tab) {
    $(tab).tab("show");
  }

  enableTestTab() {
    var testTab = this.navigation.find("#test-tab");

    if (this.printer.name.length) {
      setTimeout(function() {
        testTab.removeClass("disabled");
        $(".js-test-tab-tooltip").tooltip("dispose");
        $(".js-test-tab-btn").removeClass("disabled");
      }, 50);
    }
  }
}

export { ChitChatsPrinterAppNav };
